// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReportFrame {
    display: block;       /* iframes are inline by default */
    background: #000;
    border: none;         /* Reset default border */
    height: 75vh;        /* Viewport-relative units */
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/report/TableauReport.css"],"names":[],"mappings":"AAAA;IACI,cAAc,QAAQ,kCAAkC;IACxD,gBAAgB;IAChB,YAAY,UAAU,yBAAyB;IAC/C,YAAY,SAAS,4BAA4B;IACjD,WAAW;AACf","sourcesContent":[".ReportFrame {\n    display: block;       /* iframes are inline by default */\n    background: #000;\n    border: none;         /* Reset default border */\n    height: 75vh;        /* Viewport-relative units */\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
