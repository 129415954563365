// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.visops-health-button {
    position: absolute;
    right: 1em;
    top: 4.5%;
    transform: translateY(-50%);
    transition: transform 0.3s ease;
}

.visops-health-button i:hover {
    transform: scale(1.2, 1.2) translate3d(0, 0, 0);
}

.MuiTab-root {
    color: black !important;
    background-color: lightgray !important;
    border: 1px solid black !important;
}

.MuiTabs-root .Mui-selected {
    color: white !important;
    background-color: green !important;
    border: 1px solid black !important;
}

.MuiTabs-indicator {
    background-color: green !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/dashboard.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,2BAA2B;IAC3B,+BAA+B;AACnC;;AAEA;IACI,+CAA+C;AACnD;;AAEA;IACI,uBAAuB;IACvB,sCAAsC;IACtC,kCAAkC;AACtC;;AAEA;IACI,uBAAuB;IACvB,kCAAkC;IAClC,kCAAkC;AACtC;;AAEA;IACI,kCAAkC;AACtC","sourcesContent":[".visops-health-button {\n    position: absolute;\n    right: 1em;\n    top: 4.5%;\n    transform: translateY(-50%);\n    transition: transform 0.3s ease;\n}\n\n.visops-health-button i:hover {\n    transform: scale(1.2, 1.2) translate3d(0, 0, 0);\n}\n\n.MuiTab-root {\n    color: black !important;\n    background-color: lightgray !important;\n    border: 1px solid black !important;\n}\n\n.MuiTabs-root .Mui-selected {\n    color: white !important;\n    background-color: green !important;\n    border: 1px solid black !important;\n}\n\n.MuiTabs-indicator {\n    background-color: green !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
